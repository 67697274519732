import React from 'react'
import Hero from '../../components/Hero'
import Layout from '../../components/layout'
import { Img } from '@tueri/react'
import Helmet from 'react-helmet'

const meta = {
  title: 'Remote image sources',
  image: '274877907175',
  path: '/features/remote-image-sources/',
  description: 'Stream remotely hosted images through our image pipeline',
  keywords: 'image hosting, image pipeline'
}

const ImageOptimization = ({ data }) => (
    <Layout blog>
          <Helmet
            html={[
              { lang: 'en', prefix: 'og: http://ogp.me/ns#' }
            ]}
            meta={[
              { property: 'og:title', content: 'Tueri - ' + meta.title },
              { property: 'twitter:title', content: 'Tueri - ' + meta.title },
              { property: 'og:type', content: 'website' },
              { property: 'og:image', content: 'https://cdn.tueri.io/' + meta.image + '/' + encodeURIComponent(meta.title) + '.jpg?w=1200&height=1200' },
              { property: 'twitter:image:src', content: 'https://cdn.tueri.io/' + meta.image + '/' + encodeURIComponent(meta.title) + '.jpg?w=1200&height=1200' },
              { property: 'og:url', content: 'https://tueri.io' + meta.path },
              { property: 'og:description', content: meta.description },
              { property: 'twitter:description', content: meta.description },
              { name: 'description', content: meta.description },
              { name: 'keywords', content: meta.keywords },
              { name: 'author', content: 'Dane Stevens' },
              { property: 'twitter:card', content: 'summary_large_image' }
            ]}
          >
            <title>Tueri - { meta.title }</title>
          </Helmet>

        <Hero
            large
            title={ meta.title }
            subTitle={ meta.description }
        />

        <div style={{
            boxShadow: '0 6px 24px rgba(0, 0, 0, 0.4)',
            borderRadius: '4px',
            overflow: 'hidden',
            marginBottom: '10rem'
        }}>
       	    <Img src={ meta.image } alt='Remote image sources' />
        </div>

        <h2>
            Already have your images online?
        </h2>

        <p>
            No problem!
        </p>

        <p>
            Tell us where your images are stored and we'll fetch them remotely, transform and deliver them just as if they were stored directly in Tueri.
        </p>

    </Layout>
)

export default ImageOptimization